.theme-edison {
  .app-toaster {
    position: fixed !important;
    color: white;
    h3 {
      color: white;
      line-height: 21px;
      font-size: 16.38px;
      margin-block-end: 16.38px;
      margin-block-start: 16.38px;
    }
    p,
    span {
      font-size: 14px;
      line-height: 18px;
      margin-block-end: 10px;
      margin-bottom: 10px;
      word-break: break-word;
    }
  }
}
