@import '../base/variables';
@import '../base/colors';

.#{$opxp_edison_theme} .header .user-menu {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all 500ms ease;
  text-align: left;
  margin: 0;

  .user-menu-button {
    .avatar-container {
      display: flex;
      align-items: center;
      position: relative;
      margin-right: 2px;
      .user-avatar.small {
        width: 24px;
        height: 24px;
      }
      .menu-badge {
        position: absolute;
        top: -12px;
        right: -12px;
        .user-menu-badge {
          display: block;
          width: 24px;
          height: 24px;
        }
      }
    }

    .user-menu-label {
      max-width: 170px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.has-badge {
      .avatar-container {
        margin-right: 12px;
      }
      .user-menu-label {
        max-width: 160px;
      }
    }
  }

  .user-menu-popover {
    display: flex;
    flex: 0 0 auto;
  }

  .user-menu-container {
    background-color: $color-white;
    width: 160px;
    padding: $padding-tiny 0;

    .user-menu-item {
      width: 100%;
      flex: 1 1 100%;
      display: flex;
      margin: 0;
      a,
      button {
        flex: 1 1 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        padding: $padding-tiny;
        padding-left: $padding-medium;
        cursor: pointer;
        .user-menu-icon {
          flex: 0 0 auto;
          margin-right: $padding-tiny;
          svg {
            fill: $color-link-btn;
          }
        }
        .user-menu-item-label {
          max-width: 102px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &:hover {
          .user-menu-icon svg {
            fill: $color-white;
          }
          .user-menu-item-badge {
            background-color: $color-white;
            color: $color-link-btn;
          }
        }
      }
    }
  }
}
