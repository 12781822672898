.adblock-modal-overlay {
  z-index: 1002;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.5);
}
.adblock-modal {
  color: #617080;
  z-index: 1003;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  width: 740px;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  background: #fff;
  padding: var(--padding-small);
  border: 1px solid rgba(0, 0, 0, 0.8);
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: var(--padding-small);
    h2 {
      color: #617080 !important;
      margin: 0;
    }
    .close-button {
      font-size: 24px;
      font-weight: 300;
      width: 32px;
      cursor: pointer;
      text-align: center;
    }
  }
  p.modal-message {
    color: #617080 !important;
    text-transform: none;
    white-space: normal;
    text-align: justify;
  }
}
